<template>
    <!--  货款对账-->
    <div class="reconciliation">
        <!--    查询条件-->
        <div id="head" class="head">
            <el-card style="margin:10px auto;line-height: 50px;">
                <el-row>
                    <el-form :inline="true" ref="form" label-width="120px">
                        <el-form-item label="查询时间:">
                            <el-date-picker
                                    size="mini"
                                    v-model="date"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    :default-time="['00:00:00','23:59:59']"
                                    end-placeholder="结束日期"
                                    value-format="timestamp"
                            >
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="运单编号:">
                            <el-input type="text" size="mini" v-model="queryData.orderNo" style="width: 150px"/>
                        </el-form-item>
                        <el-form-item>
                            <el-radio-group v-model="queryData.tabId">
                                <el-radio :label="2">已进账</el-radio>
                                <el-radio :label="1">未进账</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" class="head_group-btn" size="mini" @click="query()">查询</el-button>
                        </el-form-item>
                    </el-form>
                    <!--<el-col :span="20" style="display: flex">-->
                    <!--<div>-->
                    <!--<label class="label-font-down">查询时间:</label>-->
                    <!--<el-date-picker-->
                    <!--size="mini"-->
                    <!--v-model="date"-->
                    <!--type="daterange"-->
                    <!--range-separator="至"-->
                    <!--start-placeholder="开始日期"-->
                    <!--:default-time="['00:00:00','23:59:59']"-->
                    <!--end-placeholder="结束日期"-->
                    <!--value-format="timestamp"-->
                    <!--&gt;-->
                    <!--</el-date-picker>-->
                    <!--</div>-->
                    <!--<div>-->
                    <!--<label class="label-font-down">运单编号:</label>-->
                    <!--<el-input type="text" size="mini" v-model="queryData.orderNo" style="width: 150px"/>-->
                    <!--</div>-->
                    <!--<div style="margin-left: 15px;">-->
                    <!--<el-radio-group v-model="queryData.tabId">-->
                    <!--<el-radio :label="2">已进账</el-radio>-->
                    <!--<el-radio :label="1">未进账</el-radio>-->
                    <!--</el-radio-group>-->
                    <!--</div>-->
                    <!--</el-col>-->
                    <!--<el-col :span="4" class="head_group pull-right">-->
                    <!--<button type="button" class="head_group-btn" @click="query()">查询</button>-->
                    <!--</el-col>-->
                </el-row>
            </el-card>
            <!--已进账-->
            <div style="min-height: 580px;" v-show="status" :class="paymentList.length === 0 ? 'pic' : 'pic1'"
                 v-loading="loading">
                <div style="width: 100%;margin: auto">
                    <el-row style="margin-top: 30px">
                        <el-col :span="8" v-for="(item,index) in paymentList" :key="index">
                            <el-card class="box-card elCardText" @click.native="toDetail(item.id)">
                                <div style="margin-bottom: 30px;font-size: 15px;color: #2b3f69;line-height: 20px;">
                                    <span style="margin-right:3%">放款银行:</span>
                                    <span>{{item.lendersBank}}</span>
                                    <el-button class="pull-right" style="padding: 3px 0" type="text">明细</el-button>
                                </div>
                                <div style="margin:40px 0;opacity: 0.7;font-size: 14px;">
                                    <span style="color: #2b3f69;">银行卡号:</span>
                                    <span class="pull-right">{{item.bankcard}}</span>
                                </div>
                                <div style="margin:40px 0;opacity: 0.7;font-size: 14px;">
                                    <span style="color: #2b3f69;">应收货款:</span>
                                    <span class="red-text pull-right">￥{{item.payReceivable}}</span>
                                </div>
                                <div style="margin-top:30px;opacity: 0.7;font-size: 14px;color: #2b3f69;">
                                    <span>放款时间:</span>
                                    <span class="pull-right">{{item.lendingTime}}</span>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <!--未进账-->
            <div style="min-height: 580px;" :class="paymentList.length === 0 ? 'pic' : 'pic1'" v-loading="loading"
                 v-show="status1">
                <div style="width: 100%;margin: auto">
                    <el-row style="margin-top: 20px;margin-bottom: 100px">
                        <el-col :span="8" v-for="item in paymentList" :key="item.index">
                            <el-card class="box-card elCardText">
                                <div style="margin-bottom: 25px">
                                    <span style="font-size: 15px;color: #2b3f69;">货物编号:&nbsp;{{item.cargoNumber}}</span>
                                </div>
                                <div style="margin:15px 0">收货人:
                                    <span class="pull-right">{{item.receiver}}</span>
                                </div>
                                <div style="margin:15px 0">收货电话:
                                    <span class="pull-right">{{item.receiverTel}}</span>
                                </div>
                                <div style="margin:15px 0">运单状态:
                                    <span class="pull-right">{{item.waybillStatus}}</span>
                                </div>
                                <div style="margin:15px 0">下单时间:
                                    <span class="pull-right">{{item.date}}</span>
                                </div>
                                <div style="margin-top:15px">代收货款:
                                    <span class="pull-right red-text">¥{{item.payment}}</span>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                </div>
            </div>

            <!--<div id="head1" class="head">-->
                <el-card style="margin:10px auto;line-height: 50px;padding: 0 20px 0 20px">
                    <div style="float: left">
                        共计<span class="red-text">{{orderAmount}}</span>笔运单，合计：
                        <span class="red-text">￥{{goodsFee}}</span>
                    </div>
                    <div class="pull-right">
                        <el-button type="primary" size="mini" @click="leadOut">导出</el-button>
                    </div>
                </el-card>
            <!--</div>-->
        </div>






        <!--统计-->
        <!--<div class="head"-->
             <!--style="position: fixed;bottom: 0px;width: 100%;background-color: #ffffff;padding-bottom: 10px;">-->
            <!--<el-card style="margin:10px auto;line-height: 50px;padding: 0 20px 0 20px">-->
                <!--<div style="float: left">-->
                    <!--共计<span class="red-text">{{orderAmount}}</span>笔运单，合计：-->
                    <!--<span class="red-text">￥{{goodsFee}}</span>-->
                <!--</div>-->
                <!--<div class="pull-right">-->
                    <!--<el-button type="primary" size="mini" @click="leadOut">导出</el-button>-->
                <!--</div>-->
            <!--</el-card>-->
        <!--</div>-->


        <!--    明细-->
        <el-dialog
                title="对账明细"
                style="text-align: center;"
                :visible.sync="dialogVisible"
                width="30%"
                :before-close="handleClose"
                class="dialog"
        >
            <div style="display: flex;" class="goodsPayment">
                <div style="width: 50%;height:100%;display: flex;flex-direction: column;text-align: left;padding:20px;">
                    <p style="text-align: center;font-weight: bold;color:#000000">入账信息</p>
                    <p><span style="color: #B2A9A9;">时间：</span><span style="color:#000000">{{goodsPayment.time}}</span>
                    </p>
                    <p><span style="color: #B2A9A9;">银行：</span><span style="color:#000000">{{goodsPayment.bank}}</span>
                    </p>
                    <p><span style="color: #B2A9A9;">卡号：</span><span
                            style="color:#000000">{{goodsPayment.cardNumber}}</span></p>
                    <p><span style="color: #B2A9A9;">持卡人：</span><span
                            style="color:#000000">{{goodsPayment.cardHolder}}</span></p>

                    <div style="margin-top: 20px;" class="blue-text">
                        <p>代收货款：¥ {{goodsPayment.collectionGoods}}</p>
                        <p>手续费：¥ {{goodsPayment.serviceCharge}}</p>
                        <p>短信费：¥ {{goodsPayment.smsFee}}</p>
                        <p>实际入账：¥ {{goodsPayment.actualEntry}}</p>
                        <p>转款手续费：¥ {{goodsPayment.loanTransferFee}}</p>
                    </div>

                </div>
                <div class="goodsPayment pull-right"
                     style="width: 50%;height:300px;display: flex;flex-direction: column;text-align: left;padding:20px;">
                    <p style="text-align: center;font-weight: bold;color:#000000">运单信息<span style="color: red">(共{{total}}单)</span>
                    </p>
                    <div style="overflow: auto">
                        <div style="" v-for="(item,index) in goodsPayment.data" :key="index">
                            <p><span style="color: #B2A9A9;">运单号：</span><span style="color:#000000">{{item.serialNumber}}</span>
                            </p>
                            <p><span style="color: #B2A9A9;">收货人：</span><span
                                    style="color:#000000">{{item.consignee}}</span></p>
                            <p><span style="color: #B2A9A9;">收货电话：</span><span style="color:#000000">{{item.receivingTelephone}}</span>
                            </p>
                            <p><span style="color: #B2A9A9;">代收货款：</span><span style="color:#000000">¥ {{item.collectionGood}}</span>
                            </p>
                            <p><span style="color: #B2A9A9;">手续费： </span><span style="color:#000000">¥ {{item.serCharge}}</span>
                            </p>
                            <p><span style="color: #B2A9A9;">短信费：</span><span
                                    style="color:#000000">¥ {{item.smsFee}}</span></p>
                            <p><span style="color: #B2A9A9;">实际入账：</span><span
                                    style="color:#000000">¥ {{item.actEntry}}</span></p>
                            <p><span style="color: #B2A9A9;">转款手续费： </span><span style="color:#000000">¥ {{item.loanTransferFee}}</span>
                            </p>
                            <div v-show="goodsPayment.data.length>1"
                                 style="background-color:#979797;width: 200px;height: 1px;margin:10px auto"></div>
                        </div>
                    </div>
                </div>
            </div>


        </el-dialog>
    </div>
</template>

<script>
    import {exportExcel} from '../api/exportExcel';
    import {
        goodsPaymentCheckList,
        goodsPaymentCheckStatistics,
        goodsPaymentCheckDetail,
        exportData
    } from "../api/goodsPayment";

    export default {
        data() {
            return {
                total: 0,
                loading: false,
                pageNum: 1,
                pageSize: 1000,
                goodsPaymentId: '', //进账记录id
                goodsPayment: [],//明细-入账信息
                goodsFee: 0,//总金额
                orderAmount: 0,//总条数
                paymentList: [],//进账未进帐列表
                dialogVisible: false,
                status: false,//已进账
                status1: false,//未进账
                date: [this.toTimeStamp(this.firstDay()), this.toTimeStamp(this.getNowFormatDate())],
                queryData: {
                    orderNo: '',
                    tabId: 2,
                }
            };
        },
        methods: {
            //日期转时间戳
            toTimeStamp(times) {
                if (times === '' || times === null || !times) {
                    return '';
                }
                let dates = times.split("-"),
                    datex = new Date(dates[0], dates[1] - 1, dates[2], 0, 0, 0);
                return datex = Date.parse(datex);
            },
            //定义当前日期
            getNowFormatDate() {
                var date = new Date();
                var seperator1 = "-";
                var seperator2 = ":";
                var month = date.getMonth() + 1;
                var strDate = date.getDate();
                if (month >= 1 && month <= 9) {
                    month = "0" + month;
                }
                if (strDate >= 0 && strDate <= 9) {
                    strDate = "0" + strDate;
                }
                var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate;

                return currentdate;
            },
            //定义本月的开始日期
            firstDay() {
                let date_ = new Date();
                let year = date_.getFullYear();
                let month = date_.getMonth() + 1;
                if (month >= 1 && month <= 9) {
                    month = "0" + month;
                }
                let firstDate = year + '-' + month + '-01';
                return firstDate
            },
            //时间戳转日期
            todateTimelocal(timeStamp) {
                if (timeStamp === '' || timeStamp === null || timeStamp === undefined || timeStamp === '0' || timeStamp === 0) return '';
                var date = new Date();
                date.setTime(timeStamp);
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                m = m < 10 ? ('0' + m) : m;
                var d = date.getDate();
                d = d < 10 ? ('0' + d) : d;
                var h = date.getHours();
                h = h < 10 ? ('0' + h) : h;
                var minute = date.getMinutes();
                var second = date.getSeconds();
                minute = minute < 10 ? ('0' + minute) : minute;
                second = second < 10 ? ('0' + second) : second;
                return timeStamp = y + '-' + m + '-' + d;
            },
            leadOut() {
                let formData = {
                    startDate: this.date[0],
                    endDate: this.date[1] + 999,
                    orderNo: this.queryData.orderNo,
                    tabId: this.queryData.tabId,
                }
                exportData(formData).then((res) => {
                    let url = window.URL.createObjectURL(new Blob([res]));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    if (formData.tabId == 1) {//未进账
                        link.setAttribute('download', '货款对账(未进账).xlsx');
                    }
                    if (formData.tabId == 2) {//已进账
                        link.setAttribute('download', '货款对账(已进账).xlsx');
                    }
                    document.body.appendChild(link);
                    link.click();
                })
            },
            // leadOut() {//未进账导出
            //   if(this.queryData.tabId == 1){
            //     const arr = this.paymentList;
            //     if(arr.length == 0){
            //       this.$message.error('暂无可导出数据!')
            //       return false
            //     }
            //     const allRecordList = arr.map((item, index) => {
            //       return {
            //         收货人: item.receiver,
            //         货物编号: item.cargoNumber,
            //         收货电话: item.receiverTel,
            //         运单状态: item.waybillStatus,
            //         下单时间: item.date,
            //         代收货款: item.payment,
            //       };
            //     });
            //     const data = {
            //       货款对账: allRecordList,
            //     };
            //     const columnHeadersTitle =['开始日期', this.todateTimelocal(this.date[0]), '结束日期', this.todateTimelocal(this.date[1]), '代收合计', this.goodsFee, '笔数', this.orderAmount, '', '', '',];
            //     const columnHeaders = {
            //       货款对账: ['货物编号', '代收货款', '下单时间', '收货人', '收货电话', '运单状态',],
            //     };
            //
            //     const cellStyle = [{ wch: 15 }, { wch: 10 }, { wch: 10 }, { wch: 10 },{ wch: 15 }, { wch: 10 }];
            //     const fileName = '货款对账';
            //     exportExcel(columnHeadersTitle,columnHeaders, data, cellStyle, fileName);
            //   }//已进账导出
            //   if(this.queryData.tabId == 2){
            //     const arr = this.paymentList;
            //     if(arr.length == 0){
            //       this.$message.error('暂无可导出数据!')
            //       return false
            //     }
            //     const allRecordList = arr.map((item, index) => {
            //       return {
            //         放款银行: item.lendersBank,
            //         银行卡号: item.bankcard,
            //         持卡人: item.accountname,
            //         代收货款: item.agentfee,
            //         短信费: item.smsfee,
            //         手续费: item.arr.serviceCharge,
            //         运单号: item.brr.serialNumber,
            //         实际入账: item.arr.actualEntry,
            //         转款手续费: item.arr.loanTransferFee,
            //         收货人: item.brr.consignee,
            //         收货电话: item.brr.receivingTelephone,
            //       };
            //     });
            //     const data = {
            //       货款对账: allRecordList,
            //     };
            //     const columnHeadersTitle =['开始日期', this.todateTimelocal(this.date[0]), '结束日期', this.todateTimelocal(this.date[1]), '合计', this.goodsFee, '笔数', this.orderAmount, '', '', '',];
            //     const columnHeaders = {
            //       货款对账: ['运单号', '放款银行', '银行卡号', '持卡人', '代收货款', '手续费', '短信费', '实际入账', '转款手续费', '收货人', '收货电话',],
            //     };
            //     const cellStyle = [{ wch: 15 }, { wch: 10 }, { wch: 18 }, { wch: 10 },{ wch: 15 }, { wch: 10 },{ wch: 10 },{ wch: 10 },{ wch: 10 },{ wch: 10 },{ wch: 15 }];
            //     const fileName = '货款对账';
            //     exportExcel(columnHeadersTitle,columnHeaders, data, cellStyle, fileName);
            //   }
            // },
            //明细
            getDetail(val) {
                goodsPaymentCheckDetail({id: val}).then((res) => {
                    if (res.code === 200) {
                        this.goodsPayment = res.data;
                        this.total = this.goodsPayment.data.length;
                        this.paymentList.forEach((item, index) => {
                            this.$set(this.paymentList[index], "arr", this.goodsPayment);
                            this.$set(this.paymentList[index], "brr", this.goodsPayment.data[0]);
                        })
                    }
                })
            },
            //查询
            query() {
                this.loading = true;
                let params = {
                    startDate: this.date[0],
                    endDate: this.date[1] + 999,
                    orderNo: this.queryData.orderNo,
                    tabId: this.queryData.tabId,
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                };
                if (params.tabId == 2) {
                    this.status = true;
                    this.status1 = false;
                }
                if (params.tabId == 1) {
                    this.status = false;
                    this.status1 = true;
                }
                this.getTotal();
                goodsPaymentCheckList(params).then((res) => {
                    if (res.code === 200) {
                        if (res.data.list.length == 0) {
                            this.$message({
                                message: "未查到数据",
                                type: "success",
                                offset: 80,
                            });
                        }
                        this.paymentList = res.data.list;
                        this.loading = false;
                        if (params.tabId == 2) {
                            this.paymentList.forEach((item, index) => {
                                var reg = /^(\d{4})\d+(\d{4})$/;
                                item.bankcard = item.bankcard.replace(reg, "$1 **** **** $2");
                            })
                        }
                    }
                })
            },
            //统计
            getTotal() {
                let params = {
                    startDate: this.date[0],
                    endDate: this.date[1],
                    serialNo: this.queryData.orderNo,
                    tabId: this.queryData.tabId,
                    mid: JSON.parse(localStorage.getItem("userObj")).custNumb,//登录用户会员号
                }
                goodsPaymentCheckStatistics(params).then((res) => {
                    if (res.code === 200) {
                        if (params.tabId == 1) {
                            this.orderAmount = res.data.orderAmount;
                            this.goodsFee = res.data.goodsFee;
                        }
                        if (params.tabId == 2) {
                            this.orderAmount = res.data.numberPens;
                            this.goodsFee = res.data.total;
                        }
                    }
                })
            },
            handleClose() {
                this.dialogVisible = false;
            },
            toDetail(val) {
                this.dialogVisible = true;
                this.getDetail(val)
            }
        },
        mounted() {
            this.query();
        }
    };
</script>

<style lang="scss" scoped>
    .dialog /deep/ .el-dialog__title {
        font-weight: bold;
    }

    .goodsPayment p {
        margin: 2px 0;
        font-size: 17px
    }

    .blue-text {
        color: #0C7BE7
    }

    .red-text {
        color: #FB3A39
    }

    .pull-right {
        float: right
    }

    .dialog .el-dialog__header {
        padding-top: 20px !important;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    .box-card div {
        //opacity: 0.7;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: Regular;
        text-align: left;
        color: #2b3f69;
        line-height: 20px;
    }

    .head_group .head_group-btn {
        display: inline-block;
        line-height: 1;
        white-space: nowrap;
        cursor: pointer;
        background: #2f7bff;
        border: 1px solid #ffffff;
        color: #ffffff;
        -webkit-appearance: none;
        text-align: center;
        box-sizing: border-box;
        outline: none;
        margin: 0;
        transition: .1s;
        font-weight: 500;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        padding: 9px 15px;
        font-size: 12px;
        border-radius: 3px;
    }

    .head_group-moreList {
        height: 50px
    }

    .head_group > input {
        height: 28px;
        width: 100px;
        border: 1px solid #d5d5d5;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 12px;
    }

    .pull-right {
        float: right
    }

    .pull-left {
        float: left
    }

    span {
        color: #333333;
    }

    .MarginLeft {
        margin-left: 8px !important;
    }

    .reconciliation {
        width: 100%;
        height: 100%;
    }

    .label-font-down {
        margin: 5px 8px;
        font-size: 14px;
        font-family: Microsoft YaHei, Microsoft YaHei-Normal;
        font-weight: normal;
        text-align: LEFT;
        color: #333333;
    }

    .head {
        margin: 60px 150px;
    }

    .box-card {
        width: 90%;
        margin: 20px 0;
    }

    .pic {
        margin: 0 auto;
        background-position: center;
        background-size: 240px 180px;
        background-image: url("../assets/img/kong.png");
        text-align: center;
        width: 300px;
        height: 300px;
        background-color: #ffffff;
        border-radius: 8px;
        background-repeat: no-repeat;
    }

    .pic1 {
        margin: 0 0px 60px 0px;
    }

    /deep/ .el-card__body, .el-main {
        padding: 1px !important;
    }

    /deep/ .el-form--inline .el-form-item__content {
        display: inline-block;
        vertical-align: unset !important;
    }

    .el-form-item {
        margin-bottom: 0px !important;
    }

    .elCardText {
        cursor: pointer;
        padding: 20px
    }
</style>
