import request from "../utils/request";

export function goodsPaymentCheckList(params) {
    return request({
        url: "insteadGoodsFee/goodsPaymentCheckList",
        method: "post",
        params: params,
    });
}
export function goodsPaymentCheckStatistics(params) {
    return request({
        url: "insteadGoodsFee/goodsPaymentCheckStatistics",
        method: "post",
        params: params,
    });
}
//货款对账详情
export function goodsPaymentCheckDetail(params) {
    return request({
        url: "insteadGoodsFee/goodsPaymentCheckDetail",
        method: "post",
        params: params,
    });
}
//导出
export function exportData(params) {
    return request({
        url: "insteadGoodsFee/exportData",
        method: "post",
        params: params,
        responseType: 'blob',
    });
}